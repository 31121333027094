/* fallback */
@font-face {
  font-family: 'Material Icons', sans-serif;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/MaterialIcons-Regular.eot?v=2.2.0');
  src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url('../fonts/MaterialIcons-Regular.eot?#iefix&v=2.2.0') format('embedded-opentype'),
    url('../fonts/MaterialIcons-Regular.woff2?v=2.2.0') format('woff2'),
    url('../fonts/MaterialIcons-Regular.woff?v=2.2.0') format('woff'),
    url('../fonts/MaterialIcons-Regular.ttf?v=2.2.0') format('truetype'),
    url('../fonts/MaterialIcons-Regular.svg?v=2.2.0#fontawesomeregular') format('svg');
}

.material-icons {
  font-family: 'Material Icons', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Material Icons Outlined', sans-serif;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/MaterialIcons-Outlined.woff2?v=2.2.0') format('woff2');
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'Material Icons Round', sans-serif;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/MaterialIcons-Round.woff2?v=2.2.0') format('woff2');
}

.material-icons-round {
  font-family: 'Material Icons Round', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'Material Icons Two Tone', sans-serif;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/MaterialIcons-Two-Tone.woff2?v=2.2.0') format('woff2');
}

.material-icons-two-tone {
  font-family: 'Material Icons Two Tone', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'Material Icons Sharp', sans-serif;
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconssharp/v9/oPWQ_lt5nv4pWNJpghLP75WiFR4kLh3kvmvRImcycg.woff2) format('woff2');
}

.material-icons-sharp {
  font-family: 'Material Icons Sharp', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
