// Import our custom theme
@import 'sass/app-theme.scss';
@import 'sass/fonts.scss';
@import 'sass/cartus-palette.scss';
@import 'sass/app.scss';
@import 'sass/common-styles.scss';
@import 'sass/dialog.scss';
@import 'sass/snackbar.scss';
@import 'sass/mat-table.scss';
@import 'sass/mat-table-column.scss';
@import 'sass/breadcrum.scss';
@import 'sass/mixin.scss';
@import 'sass/cartus-palette.scss';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import 'sass/card.scss';

@mixin custom-components-theme($theme, $typography, $cartus-palette) {
  @include app($theme, $typography, $cartus-palette);
}

// @include angular-material-theme($app-theme);
.ccu-theme {
  // use our theme with angular-material-theme mixin
  // @include angular-material-theme($app-theme);
  // include custom components theme mixin once per theme class
  @include custom-components-theme($app-theme, $typography, $cartus-palette);
}

html,
body {
  height: 100%;
  box-sizing: border-box;
  --mat-tab-header-inactive-ripple-color: transparent;
  // background-color: map-get($cartus-palette, cartus_nav_tile);;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  min-width: 331px;
  font-family: 'Avenir-Book', sans-serif;
  font-size: 16px;
  color: map-get($cartus-palette, 'cartus-gray');
  -moz-osx-font-smoothing: grayscale;
  /* Fix blurry font for Firefox Mac users connected to external displays */
}

.mat-mdc-card {
  &:focus {
    outline: 2px solid map-get($cartus-palette, cartus_dark_accent_blue);
  }
}

.mdc-tab__ripple {
  display: none;
}

*[fxlayoutgap] > *:last-child {
  margin-right: 0;
  /* Fix for flex last column in a row having a right margin */
}

/* width */

::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

/* Track */

::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #707070;
  border-radius: 20px;
}

button:focus {
  outline: 2px solid map-get($cartus-palette, cartus_dark_accent_blue);
  border-radius: 10px;
}

button,
*[role='button'],
a {
  cursor: pointer !important;
}

input[type='number'] {
  -moz-appearance: textfield;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

//Custom material colors
.mat-mdc-form-field.mat-focused .mat-form-field-label,
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled),
.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: map-get($cartus-palette, 'cartus_dark_accent_blue');
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field.mat-focused .mat-form-field-ripple {
  background-color: map-get($cartus-palette, 'cartus_dark_accent_blue');
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: map-get($cartus-palette, 'cartus_primary_blue') !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: map-get($cartus-palette, 'cartus_primary_blue') !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:disabled:checked + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: map-get($cartus-palette, 'cartus_primary_blue') !important;
  opacity: unset !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:disabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: map-get($cartus-palette, 'cartus_primary_blue') !important;
  opacity: unset !important;
}

.mat-mdc-radio-button.mat-accent[_ngcontent-ng-c3755102424] {
  --mat-radio-ripple-color: #2065f8;
  --mat-radio-checked-ripple-color: none;
  --mdc-radio-unselected-hover-icon-color: gray;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.disabled-check.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: map-get($cartus-palette, 'cartus_disabled_gray');
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
  background-color: map-get($cartus-palette, 'cartus_primary_blue') !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: map-get($cartus-palette, 'cartus_primary_blue') !important;
}

.mat-icon.grey-color {
  color: map-get($cartus-palette, cartus_bright_grey);
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 16px;
}

.mat-mdc-form-field .mat-input-element,
.mat-form-field .mat-select-content,
.mat-form-field .mat-select-value {
  padding-bottom: 7px;
}

.mat-mdc-form-field.mat-focused .mat-form-field-label,
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled),
.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: map-get($cartus-palette, cartus_bright_grey);
}

.mdc-text-field {
  padding: 0 !important;
}

// Manipulate initial background color
.mdc-text-field--filled {
  background-color: white !important;
}

// Manipulate background color on hover and focus
.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

//styles for last div of timeline
.last-div {
  .timeline-wrapper {
    .circle-wrapper {
      .circle {
        &::after {
          display: none;
        }
      }
    }
  }
}

.next {
  @include button-style(
    160px,
    2px solid map-get($cartus-palette, cartus_primary_blue),
    map-get($cartus-palette, cartus_primary_blue),
    0 24px 0 0,
    map-get($cartus-palette, cartus_nav_tile),
    16px
  );
}

.next-disabled {
  @include button-style(160px, 2px solid #cccccc, #cccccc, 0 24px 0 0, map-get($cartus-palette, cartus_nav_tile), 16px);
}

.previous {
  @include button-style(
    160px,
    2px solid map-get($cartus-palette, cartus_primary_blue),
    transparent,
    0 48px 0 0,
    map-get($cartus-palette, cartus_primary_blue),
    16px
  );
}

.submit {
  @include button-style(
    160px,
    2px solid map-get($cartus-palette, cartus_primary_blue),
    map-get($cartus-palette, cartus_primary_blue),
    0 24px 0 0,
    map-get($cartus-palette, cartus_nav_tile),
    16px
  );
}

.submit-disabled {
  @include button-style(160px, 2px solid #cccccc, #cccccc, 0 24px 0 0, map-get($cartus-palette, cartus_nav_tile), 16px);
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-infix {
  padding: 10px 0;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  color: map-get($cartus-palette, cartus_primary_dark_brown);
  font-size: 12px;
  font-family: 'Avenir-Medium', sans-serif;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-layout .mat-checkbox-label {
  font-family: 'Avenir-Medium', sans-serif;
  font-size: 16px;
  color: map-get($cartus-palette, cartus_primary_dark_brown);
}

.mat-mdc-option-text,
.mat-option {
  font-size: 16px;
}

.mat-mdc-form-field {
  .mat-mdc-input-element,
  .mat-mdc-select-value,
  .mat-mdc-select-value-text {
    color: map-get($cartus-palette, cartus_bright_grey) !important;
    font-family: 'Avenir-Medium', sans-serif !important;
    font-size: 16px;
  }
}

.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}

.mat-mdc-input-element {
  caret-color: map-get($cartus-palette, cartus_primary_blue);
  text-overflow: ellipsis;
}
.custom-tooltip .mdc-tooltip__surface {
  max-width: unset !important;
  font-size: 9px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field.mat-form-field-invalid,
.mat-form-field-label {
  font-family: 'Avenir-Book';
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-legacy .mat-form-field-label {
  font-family: 'Avenir-Book';
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.275em) scale(1) perspective(100px) translateZ(0.001px) !important;
}

::ng-deep .custom-dialog {
  .mat-mdc-dialog-container {
    padding: 20px 0px 44px 26px;
    position: relative;
    border-radius: 10px;
  }
}

::ng-deep .activate-benefit-dialog {
  .mat-mdc-dialog-container {
    border-radius: 10px;
    padding: 13px;
  }
}

::ng-deep #dialog2 {
  .mat-mdc-dialog-content {
    text-align: center;
    padding: 0;
    border-radius: 10px;

    .button-wrapper {
      margin-top: 20px;

      .delete-card {
        border: 2px solid map-get($cartus-palette, cartus_primary_blue);
        border-radius: 5px;
        color: map-get($cartus-palette, cartus_primary_blue);
        font-family: 'Avenir-Medium', sans-serif;
        margin-right: 10px;
      }

      .cancel {
        background-color: map-get($cartus-palette, cartus_primary_blue);
        border-radius: 5px;
        color: #ffffff;
        border: 2px solid map-get($cartus-palette, cartus_primary_blue);
        font-family: 'Avenir-Medium', sans-serif;
      }
    }
  }
}

::ng-deep .complete-family-custom-dialog {
  .mat-mdc-dialog-container {
    padding-top: 22px;
  }
}

//mat menu style for profile-dropdown in header//
::ng-deep.cdk-overlay-pane .mat-mdc-menu-panel {
  max-width: 100% !important;

  .mat-mdc-menu-content {
    box-shadow: 0px 3px 5px map-get($cartus-palette, cartus_card_shadow);

    button.mat-mdc-menu-item {
      color: map-get($cartus-palette, cartus_dark_accent_blue);
      font-size: 16px;
      font-family: 'Avenir-Medium', sans-serif;

      &:focus {
        outline: none;
      }

      &:hover {
        background-color: #fafadc;
      }
    }

    .menu-image {
      margin-right: 20px;
      width: 30px;
      object-fit: scale-down;
    }
  }
}

//sidenav content background
.mat-drawer-content {
  background-color: map-get($cartus-palette, cartus_white_smoke);
}

//mobile title styles
.active-title {
  color: map-get($cartus-palette, cartus_primary_blue);
  font-size: 20px;
  font-family: 'Avenir-Medium', sans-serif;
  margin: 25px 0 13px 0;

  mat-icon {
    padding-left: 5px;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mat-bottom-sheet-container-large {
  border-radius: 16px 16px 0px 0px !important;
}

.mat-bottom-sheet-container {
  width: 90vw !important;
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  ::ng-deep .custom-dialog {
    max-width: 92vw !important;
    overflow: auto !important;

    .mat-mdc-dialog-container {
      padding: 28px 14px 22px 20px;
    }

    .mat-mdc-dialog-content {
      max-height: unset;
    }
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  ::ng-deep .custom-dialog {
    max-width: 92vw !important;
    overflow: auto !important;

    .mat-mdc-dialog-container {
      padding: 28px 14px 22px 20px;
    }

    .mat-mdc-dialog-content {
      max-height: unset;
      margin: 0;
      padding: 0 !important;
      overflow: unset;
    }
  }

  ::ng-deep .complete-family-custom-dialog {
    max-width: 89vw !important;
    overflow: auto !important;

    .mat-mdc-dialog-container {
      padding: 14px 14px 22px 24px;
    }
  }
}

@media screen and (max-width: 900px) {
  .mat-bottom-sheet-container {
    border-radius: 16px 16px 0px 0px !important;
    min-width: 90vw !important;
  }
}

// Utility Classes
.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.d-inline {
  display: inline;
}

.d-relative {
  position: relative;
}

.d-absolute {
  position: absolute;
}

.pointer {
  cursor: pointer !important;
}

// Skeleton Loading
.skeleton-box,
.skeleton-box-rounded,
.skeleton-box-dark,
.skeleton-box-dark-rounded {
  position: relative;
  overflow: hidden;
  box-shadow: none !important;

  &::before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #e8e8e8;
    z-index: 1;
  }

  &::after {
    content: '';
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(#fff, 0) 0, rgba(#fff, 0.2) 20%, rgba(#fff, 0.5) 60%, rgba(#fff, 0));
    animation: shimmer 2s infinite;
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.skeleton-box-rounded {
  border-radius: 16px;
}

.skeleton-box-dark {
  background-color: #d6d6d6;
}

.skeleton-box-dark-rounded {
  border-radius: 16px;
}

.mat-mdc-snack-bar-action {
  color: #fff !important;
}

.toaster {
  font-family: 'Avenir-Book', sans-serif;
  font-weight: bold;
  color: map-get($cartus-palette, cartus_white_smoke) !important;
  font-size: 20px !important;
  text-align: center;

  .mat-mdc-snack-bar-action {
    color: map-get($cartus-palette, cartus_white_smoke) !important;
    font-size: 20px !important;
    font-weight: bolder;
    background: map-get($cartus-palette, cartus_primary_blue) !important;
    border-radius: 10px;
  }
}

.backdropBackground {
  background-color: #333333;
  opacity: 0.8 !important;
}

.owl-theme .owl-nav [class*='owl-']:hover {
  color: #1670ef !important;
  background: none !important;
}

.owl-theme .owl-nav {
  position: relative;
  bottom: 130px;
}

.owl-theme .owl-nav [class*='owl-'] {
  color: #1670ef !important;
  background: none !important;
  font-size: 36px;
  width: 20px;
}

.owl-prev {
  position: absolute !important;
  left: 0px !important;
}

.owl-next {
  position: absolute !important;
  right: 0px !important;
}

.mat-icon {
  overflow: visible !important;
}

.mat-mdc-form-field {
  font-size: 16px !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  color: rgba(0, 0, 0, 0.87) !important;
  margin-top: 24px !important;
  margin-bottom: 16px !important;
}

.mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__action--primary {
  outline: 0 !important;
}

.card-body {
  padding: 16px !important;
}

.card-content {
  padding-left: 0 !important;
}

.benefit-name-btn {
  margin-bottom: 4px;
  font-size: 15px;
}

.profile-name {
  font-size: 16px;
  margin-left: 3px;
}

.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
  display: contents;
}
