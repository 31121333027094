.mat-mdc-card {
  padding: 16px;

  & > :first-child {
    margin-top: 0;
  }

  .mat-mdc-card-content {
    padding: 0;

    & > :first-child {
      margin-top: 0;
    }
  }
}
