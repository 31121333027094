.snack-container {
  width: 100%;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  max-width: max-content !important;
  min-width: 10.375em !important;
  background: #575a5d 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.251) !important;
  border-radius: 4px;
  font-family: 'Avenir-Heavy', sans-serif !important;
}

.mdc-snackbar__label {
  font-weight: 700 !important;
}

.mdc-button__label {
  font-family: Avenir-Book;
  font-size: 14px;
  font-weight: 300;
}
@media screen and (max-width: 1024px) {
  .mat-mdc-snack-bar-handset .mat-mdc-snack-bar-container {
    max-width: max-content !important;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin: 0 auto !important;
    margin: 1.5em auto !important;
  }
}
.snack-icons {
  padding-left: 0.5em;
  display: flex;
  position: relative;
  a.close .mat-icon {
    width: 3em;
    height: 3em;
    font-size: 1.2em !important;
  }
  a.save .mat-icon {
    width: 3em;
    height: 3em;
    font-size: 1.2em !important;
  }
  .mat-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6em !important;
    cursor: pointer;
    &.loader-close-icon {
      position: relative;
      font-size: 0.75em !important;
    }
  }
}

//Spinner css
.spinner-container {
  position: absolute;
  right: 7%;
  top: 0.1em;
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    position: absolute;
    right: 8%;
    top: 0.18em;
  }
  @media screen and (max-width: 600px) {
    right: 7%;
  }
}
.loader {
  border: 0.1em solid rgba(255, 255, 255, 255);
  border-radius: 50%;
  width: 1em;
  height: 1em;
  -webkit-animation: spin 6s ease-out infinite; /* Safari */
  animation: spin 6s ease-out infinite;
  transform: rotate(45deg);
}

@keyframes spin {
  25% {
    border-top: 0.1em solid #575a5d;
  }
  50% {
    border-right: 0.1em solid #575a5d;
  }
  75% {
    border-bottom: 0.1em solid #575a5d;
  }
  90% {
    border-bottom: 0.1em solid #575a5d;
  }
  95% {
    border-left: 0.1em solid #575a5d;
  }
  100% {
    border-top: 0.1em solid #575a5d;
    border-left: 0.1em solid #575a5d;
    border-right: 0.1em solid #575a5d;
    border-bottom: 0.1em solid #575a5d;
  }
}
// Spinner ends
